<template>
    <div>
        <div class="breadcrumb" v-if="isTab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
                <el-breadcrumb-item>会员资料</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="padding: 5px">
                <i class="el-icon-close icon" @click="closeClick"></i>
            </div>
        </div>

        <div style="padding: 50px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="手机号：" prop="phone" style="width: 40%">
                    <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="name" style="width: 35%">
                    <el-input v-model="ruleForm.name" placeholder="客户"></el-input>
                </el-form-item>
                <el-form-item label="临时地址：" prop="temp_location" style="width: 80%">
                    <div style="display: flex">
                        <el-input v-model="ruleForm.temp_location"></el-input>
                        <el-button type="primary" style="margin-left: 5px" @click="fillClick">填充</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="所在地：" required>
                    <!-- <el-col :span="4">
              <el-form-item prop="province">
                <el-select
                  v-model="ruleForm.province"
                  placeholder="请选择"
                  @focus="city1Focus"
                  @change="city1Change"
                >
                  <el-option
                    v-for="item in provinces"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" v-if="ruleForm.province">
              <el-form-item prop="city">
                <el-select
                  v-model="ruleForm.city"
                  placeholder="请选择"
                  @focus="city2Focus"
                  @change="city2Change"
                >
                  <el-option
                    v-for="item in cities"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" v-if="ruleForm.city">
              <el-form-item prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择"
                  @focus="city3Focus"
                  @change="city3Change"
                >
                  <el-option
                    v-for="item in regiones"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
                    <span>{{ location.join("") }} </span>
                    <el-cascader style="width:100px" placeholder="请选择地区" :options="options" v-model="location"
                        @change="ruleForm.province = location[0]; ruleForm.city = location[1]; ruleForm.region = location[2]" />
                </el-form-item>
                <el-form-item label="详细地址：" prop="address" style="width: 80%">
                    <div style="display: flex">
                        <el-input v-model="ruleForm.address"></el-input>
                        <!-- <el-button type="primary" style="margin-left: 5px" @click="getAddressList">搜索</el-button> -->
                    </div>
                </el-form-item>
                <!-- <el-form-item>
                    <el-table :data="tableData" border style="width: 80%" @row-click="rowClick">
                        <el-table-column prop="name" label="名称"> </el-table-column>
                        <el-table-column prop="address" label="道路"> </el-table-column>
                    </el-table>
                </el-form-item> -->
                <el-form-item label="所属门店：" prop="shop_id">
                    <el-select v-model="ruleForm.shop_id" clearable placeholder="请选择">
                        <el-option v-for="item in shop_ids" :key="item.id" :label="item.shop_name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="卡等级：" prop="level">
                    <el-radio-group v-model="ruleForm.level">
                        <el-radio :label="item.value" v-for="item in levels" :key="item.value">{{ item.label }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="用户类型：" prop="user_type">
                    <el-radio-group v-model="ruleForm.user_type">
                        <el-radio :label="0">个人</el-radio>
                        <el-radio :label="1">企业</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="支付方式：" prop="pay_method">
                    <el-radio-group v-model="ruleForm.pay_method">
                        <el-radio :label="0">现结</el-radio>
                        <el-radio :label="1">记账</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注：" style="width: 80%">
                    <el-input v-model="ruleForm.remark"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { pcaTextArr } from "element-china-area-data";
export default {
    props: {
        isTab: {
            default: true,
        },
    },
    data() {
        return {
            options: pcaTextArr,
            location: [],
            regiones: [],
            cities: [],
            provinces: [],
            ruleForm: {
                phone: "",
                name: "",
                temp_location: "",
                province: "",
                city: "",
                region: "",
                address: "",

                shop_id: "",
                user_type: 0,
                remark: "",
                level: 0,
                pay_method: 0,
                commercial_id: "",
            },
            tableData: [],
            levels: [
                { label: "vip0", value: 0 },
                { label: "vip1", value: 1 },
                { label: "vip2", value: 2 },
                { label: "vip3", value: 3 },
                { label: "vip4", value: 4 },
                { label: "vip5", value: 5 },
            ],
            shop_ids: [],
            myKey: "WGOBZ-JTR35-ACLIY-IGOU6-X2L63-XGFP3",
            rules: {
                phone: [{ required: true, message: "必填", trigger: "blur" }],
                name: [{ required: true, message: "必填", trigger: "blur" }],
                temp_location: [{ required: true, message: "必填", trigger: "blur" }],
                location1: [{ required: true, message: "必填", trigger: "blur" }],
                location2: [{ required: true, message: "必填", trigger: "blur" }],
                location3: [{ required: true, message: "必填", trigger: "blur" }],
                address: [{ required: true, message: "必填", trigger: "blur" }],
                shop_id: [{ required: true, message: "必填", trigger: "blur" }],
                user_type: [{ required: true, message: "必填", trigger: "blur" }],
                level: [{ required: true, message: "必填", trigger: "blur" }],
                pay_method: [{ required: true, message: "必填", trigger: "blur" }],
            },
        };
    },
    mounted() {
        this.ruleForm.commercial_id =
            localStorage.getItem("commercial_id") ||
            sessionStorage.getItem("commercial_id");
        this.$http
            .post("/system/shop/lst", {
                commercial_id: this.ruleForm.commercial_id,
            })
            .then((res) => {
                this.shop_ids = res.data.data;
                console.log(res);
            });
    },
    computed: {},
    methods: {
        closeClick() {
            this.$emit("close", true);
        },
        getAddressList() {
            this.$jsonp("https://apis.map.qq.com/ws/place/v1/search", {
                boundary: `region(${this.ruleForm.province}, 0)`,
                keyword: `${this.ruleForm.province}${this.ruleForm.city}${this.ruleForm.region}${this.ruleForm.address}`,
                page_size: 20,
                page_index: 1,
                key: this.myKey,
                output: "jsonp",
            }).then((res) => {
                console.log(res);
                this.tableData = [];
                res.data.forEach((item) => {
                    this.tableData.push({
                        name: item.title,
                        address: item.address,
                    });
                });
            });
        },
        rowClick(row) {
            this.ruleForm.address = row.address;
            this.tableData = [];
        },
        fillClick() {
            if (this.ruleForm.temp_location == "") return;
            this.ruleForm.address = this.ruleForm.temp_location;
        },
        city1Focus() {
            if (this.provinces.length == 0) {
                this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
                    key: this.myKey,
                    output: "jsonp",
                }).then((res) => {
                    res.result[0].forEach((item) => {
                        this.provinces.push({ name: item.fullname, id: item.id });
                    });
                });
            }
        },
        city2Focus() {
            if (
                this.cities.length == 0 ||
                this.ruleForm.province != sessionStorage.getItem("province")
            ) {
                sessionStorage.setItem("province", this.ruleForm.province);
                this.cities = [];
                this.provinces.forEach((item) => {
                    if (item.name == this.ruleForm.province) {
                        this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
                            key: this.myKey,
                            id: item.id,
                            callbackName: "QQmap",
                            output: "jsonp",
                        }).then((res) => {
                            res.result[0].forEach((item) => {
                                this.cities.push({
                                    name: item.fullname,
                                    id: item.id,
                                });
                            });
                        });
                    }
                });
            }
        },
        city3Focus() {
            if (
                this.regiones.length == 0 ||
                this.cities != sessionStorage.getItem("cities")
            ) {
                sessionStorage.setItem("cities", this.ruleForm.city);
                this.regiones = [];
                this.cities.forEach((item) => {
                    if (item.name == this.ruleForm.city) {
                        this.$jsonp("https://apis.map.qq.com/ws/district/v1/getchildren", {
                            key: this.myKey,
                            id: item.id,
                            callbackName: "QQmap",
                            output: "jsonp",
                        }).then((res) => {
                            console.log(res);
                            res.result[0].forEach((item) => {
                                this.regiones.push(item.fullname);
                            });
                        });
                    }
                });
            }
        },
        city1Change() {
            this.ruleForm.city = "";
            this.ruleForm.region = "";
            // this.getAddressList();
        },
        city2Change() {
            this.ruleForm.region = "";
            // this.getAddressList();
        },
        city3Change() {
            // this.getAddressList();
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$http
                        .post("/client/promoter/apply", {
                            member_name: this.ruleForm.name,
                            bind_phone: this.ruleForm.phone,
                            province: this.ruleForm.province,
                            city: this.ruleForm.city,
                            county: this.ruleForm.region,
                            address: this.ruleForm.address,
                            shop_id: this.ruleForm.shop_id,
                            level: this.ruleForm.level,
                            commercial_id: this.ruleForm.commercial_id,
                            user_type: this.ruleForm.user_type,
                            remake: this.ruleForm.remark,
                            pay_method: this.ruleForm.pay_method,
                            temp_location: this.ruleForm.temp_location,
                        })
                        .then((res) => {
                            if (res.data.status == "success") {
                                this.closeClick();
                                this.$message({
                                    type: "success",
                                    message: res.data.msg,
                                });
                                this.$emit("close");
                            }
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>
<style scoped lang="less">
.icon {
    cursor: pointer;
}

.icon:hover {
    color: red;
}

.el-select {
    width: 100%;
}

.breadcrumb {
    height: 40px;
    line-height: 40px;
    box-sizing: content-box;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 20px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
  