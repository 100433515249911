<template>
    <div>
        <div v-if="isShow == 0">
            <div>
                <bread-crumb :crumbData="['操作日志']" :chooseCrumbData="1"></bread-crumb>
                <el-row style="margin: 20px">
                    <el-col :span="9">
                        <span>创建日期：</span>
                        <el-date-picker v-model="t1" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="6">
                        <el-input placeholder="输入卡号/手机号/地址" v-model="query.search"></el-input>
                    </el-col>

                    <el-col :span="7">
                        <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery()">
                            <div style="display: flex; align-items: center; font-size: 14px">
                                <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                                <span style="margin-left: 3px">查询</span>
                            </div>
                        </el-button>
                    </el-col>
                </el-row>
                <div style="margin:20px">
                    <el-button type="primary" @click="add">添加推广员</el-button>
                </div>
                <div style="padding: 20px">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%">
                        <el-table-column prop="create_time" sortable label="创建时间"></el-table-column>
                        <el-table-column prop="member_name" sortable label="姓名"></el-table-column>
                        <el-table-column prop="bind_phone" sortable label="手机"></el-table-column>
                        <el-table-column prop="address" sortable label="地址"></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button @click="handleConfirm(scope.row)"
                                    :disabled="scope.row.status == '已确认'">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <pagination :handleQuery="handleQuery" :currentPage="query.currentPage"
                        :currentLength="query.currentLength" :total="total">
                    </pagination>
                </div>
            </div>
        </div>
        <add-group v-if="isShow == 1" @close="onClose"></add-group>
        <info v-if="isShow == 2" @close="onClose" :data="info"></info>
    </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue";
import addGroup from "./add.vue";
import info from "../label3_memberInformation/childrens/memberInfo.vue"
import { getDate } from "@/until/utilse.js";
export default {
    components: {
        pagination, addGroup, info
    },
    data() {
        return {
            info: {},
            isShow: 0,
            t1: [],
            total: 0,
            tableData: [],
            commercial_id: localStorage.getItem("commercial_id") ||
                sessionStorage.getItem("commercial_id"),
            query: {
                commercial_id: localStorage.getItem("commercial_id") ||
                    sessionStorage.getItem("commercial_id"),
                firstTime: "",
                lastTime: "",
                currentPage: 1,
                currentLength: 20,
                search: "",
            },
        };
    },
    mounted() {
        this.handleQuery()
    },
    watch: {

    },
    computed: {},
    methods: {
        onClose() {
            this.isShow = 0
        },
        async handleQuery(page = 1) {
            let query = this.query;
            let n = this.t1
            if (n?.[0]) {
                this.query.firstTime = getDate(n[0])
            } else {
                this.query.firstTime = null
            }
            if (n?.[1]) {
                this.query.lastTime = getDate(n[1])
            } else {
                this.query.lastTime = null;
            }
            let res = (await this.$http.post("/client/promoter/lst", query)).data
            this.total = res.count;
            this.tableData = res.data

            console.log(this.tableData);
        },
        async handleConfirm(row) {

            // let res = (await this.$http.post("/system/Operation_Log/edit", { id })).data;
            // if (res.status == "success") {
            //     this.$message.success("操作成功")
            // } else {
            //     this.$message("没有权限")
            // }
            // console.log("详情");
            this.info = row;
            this.isShow = 2
        },
        async add() {
            this.isShow = 1
        }
    }
};
</script>
<style scoped lang="less"

/deep/ .cell {
    padding: 0 !important;
}

</style>